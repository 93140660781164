<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row v-if="loaded">
      <v-col cols="12">
        <v-alert class="info"
          ><b>{{ $t("project.name") }}</b> - {{ $t("project.version") }}:
          <version /> -
          <router-link to="/changelog" class="noline">{{
            $t("project.changelog")
          }}</router-link>
        </v-alert>
      </v-col>
      <v-col cols="12" lg="4" v-if="data.total_clients">
        <router-link to="/pages/clients" class="noline">
          <base-panel
            :title="$t('clients.clients')"
            icon="mdi-home-group"
            class="text-right"
          >
            <h3>
              {{ $t("clients.total clients") + ": " + data.total_clients }}
            </h3>
            <h3><br /></h3>
          </base-panel>
        </router-link>
      </v-col>
      <v-col cols="12" lg="4" v-if="data.total_projects">
        <router-link to="/pages/projects" class="noline">
          <base-panel
            :title="$t('projects.projects')"
            icon="mdi-home"
            class="text-right"
          >
            <h3>
              {{ $t("projects.total projects") + ": " + data.total_projects }}
            </h3>
            <h3><br /></h3>
          </base-panel>
        </router-link>
      </v-col>
      <v-col cols="12" lg="4" v-if="data.total_users">
        <router-link to="/pages/users" class="noline">
          <base-panel
            :title="$t('users.users')"
            icon="mdi-account-multiple"
            class="text-right"
          >
            <h3>{{ $t("users.total users") + ": " + data.total_users }}</h3>
            <h3><br /></h3>
          </base-panel>
        </router-link>
      </v-col>

      <v-col cols="12" lg="4">
        <router-link to="/pages/tickets" class="noline">
          <base-panel
            :title="$t('tickets.tickets')"
            icon="mdi-ticket-account"
            class="text-right"
          >
            <h3>
              {{ $t("tickets.total tickets") + ": " + data.total_tickets }}
            </h3>
            <h3>{{ $t("tickets.open tickets") + ": " + data.open_tickets }}</h3>
          </base-panel>
        </router-link>
      </v-col>

      <v-col cols="12" md="4">
        <base-panel class="v-card-subscription" v-if="data.subscription">
          <v-card-text class="text-center">
          <h3 class="mb-2">{{ $t("subscription.subscription")}}: {{data.subscription.name}}</h3>
          <h4 class="mb-2">{{ $t("subscription.until")}}: {{subUntil}}</h4>
          <h4 class="mb-2">{{ $t("subscription.users")}}: {{data.subscription.active_users}}/{{data.subscription.paid_seats}}</h4>
          <br>
            <a class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary" target="admin" :href="adminUrl">{{ $t("subscription.manage")}}</a>
          </v-card-text>
        </base-panel>
      </v-col>
    </v-row>
    <v-alert v-else class="info">{{ $t("loading") }}</v-alert>
  </v-container>
</template>

<script>
import Version from "@/components/Version";
export default {
  name: "Dashboard",
  components: {
    Version,
  },

  data() {
    return {
      data: {},
      loaded: false,
    };
  },
  created() {
    this.get("/dashboard").then((data) => {
      this.data = data;
      this.loaded = true;
    });
  },
  methods: {},
  computed: {
    subUntil() {
      if(!this.data) {
        return "-";
      }
      var date = new Date(this.data.subscription.paid_until * 1000);
      return date.toLocaleDateString(this.$i18n.locale);
    },
    adminUrl() {
      return process.env.VUE_APP_FINADMIN_URL + "/abo/finsupport";
    }
  }
};
</script>
